<template>
  <main>
    <section class="horizon">
      <div class="container">
        <div class="in-between">
          <h2 class="horizon__title">Reporte admisión</h2>
        </div>
        <div class="row row-table">
          <table-data
            title="Nacionalidad"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$nacionalidad.nombre"
            :limit="10"
            metodo="datatable"
            name="cotizaciones"
            tableTemplate="TableReporteAdmision"
          />
        </div>
        <div class="row row-table">
          <table-data
            title="Año Cursado"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$ano_cursado"
            :limit="10"
            metodo="datatable"
            name="cotizaciones"
            tableTemplate="TableRpAnnoCursado"
          />
        </div>

        <div class="row row-table">
          <table-data
            title="Año de egreso"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$ano_egreso"
            :limit="10"
            metodo="datatable"
            name="cotizaciones"
            tableTemplate="TableReporteAdmision"
          />
        </div>

        <div class="row row-table">
          <table-data
            title="Comunas"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$comuna.nombre"
            :limit="10"
            metodo="datatable"
            name="cotizaciones"
            tableTemplate="TableReporteAdmision"
          />
        </div>

        <div class="row row-table">
          <table-data
            title="Regiones"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$region.nombre"
            :limit="20"
            metodo="datatable"
            name="cotizaciones"
            tableTemplate="TableReporteAdmision"
          />
        </div>

        <div class="row row-table">
          <table-data
            title="Carreras por sede"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$postulacion"
            :limit="20"
            metodo="datatable"
            name="cotizaciones"
            tableTemplate="TableRpCarreraPorSede02"
          />
        </div>

        <div class="row row-table">
          <table-data
            title="Sede con más cotizaciones"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$postulacion.zona_admision.nombre"
            :limit="20"
            metodo="datatable"
            name="cotizaciones"
            tableTemplate="TableReporteAdmision"
          />
        </div>

        <div class="row row-table">
          <table-data
            title="Carreras más cotizadas por sede"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$postulacion"
            :limit="20"
            match="Santiago UST"
            metodo="datatable_bysede"
            name="cotizaciones"
            tableTemplate="TableRpCarrerasPorSedeCB"
          />
        </div>

        <div class="row row-table">
          <table-data
            title="Comunas más recurrentes en cotizaciones por Sede"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$comuna.nombre"
            :limit="20"
            match="Santiago UST"
            metodo="datatable_bysede"
            name="cotizaciones"
            tableTemplate="TableReporteAdmisionCB"
          />
        </div>

        <div class="row row-table">
          <table-data
            title="Regiones más recurrentes en cotizaciones por Sede"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$region.nombre"
            :limit="20"
            match="Santiago UST"
            metodo="datatable_bysede"
            name="cotizaciones"
            tableTemplate="TableReporteAdmisionCB"
          />
        </div>

        <div class="row row-table">
          <table-data
            title="Recurrencia de instituciones en cotizaciones por Sede"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$postulacion.institucion"
            :limit="20"
            match="Santiago UST"
            metodo="datatable_bysede"
            name="cotizaciones"
            tableTemplate="TableReporteAdmisionCB"
          />
        </div>

        <div class="row row-table">
          <table-data
            title="Carreras más cotizada en todas las sedes"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$postulacion.carrera.nombre"
            :limit="20"
            metodo="datatable"
            name="cotizaciones"
            tableTemplate="TableReporteAdmision"
          />
        </div>

        <div class="row row-table">
          <table-data
            title="Carreras Sede Jornada más cotizada"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$postulacion"
            :limit="20"
            metodo="datatable"
            name="cotizaciones"
            tableTemplate="TableRpCarreraPorSede02"
          />
        </div>

        <div class="row row-table">
          <table-data
            title="Carreras Sede Jornada más consultadas por año cursado "
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$postulacion"
            :limit="20"
            :match="0"
            metodo="datatable_bycurso"
            name="cotizaciones"
            tableTemplate="TableReporteAdmisionCBCurso02"
          />
        </div>

        <div class="row row-table">
          <table-data
            title="Carreras más consultadas por año cursado"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$postulacion.carrera.nombre"
            :limit="20"
            :match="0"
            metodo="datatable_bycurso"
            name="cotizaciones"
            tableTemplate="TableReporteAdmisionCBCurso"
          />
        </div>

        <div class="row row-table">
          <table-data
            title="Carreras más consultadas por año de egreso"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$postulacion.carrera.nombre"
            :limit="20"
            match="2020"
            metodo="datatable_byegreso"
            name="cotizaciones"
            tableTemplate="TableReporteAdmisionCBAnno"
          />
        </div>

        <div class="row row-table">
          <table-data
            title="Carreras Sede Jornada más consultadas por año de egreso "
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$postulacion"
            :limit="20"
            match="2019"
            metodo="datatable_byegreso"
            name="cotizaciones"
            tableTemplate="TableReporteAdmisionCBAnno02"
          />
        </div>
        <!-- simulaciones -->

        <!-- ok -->
        <div class="row row-table">
          <table-data
            title="Beneficio de gratuidad"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$gratuito"
            :limit="20"
            metodo="datatable"
            name="simulaciones"
            tableTemplate="TableReporteAdmision"
          />
        </div>

        <div class="row row-table">
          <table-data
            title="Beneficio de Becas del Estado"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$beca_estado"
            :limit="20"
            metodo="datatable"
            name="simulaciones"
            tableTemplate="TableReporteAdmision"
          />
        </div>

        <div class="row row-table">
          <table-data
            title="Nivel socio económico"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$nivel_socioeconomico"
            :limit="20"
            metodo="datatable"
            name="simulaciones"
            tableTemplate="TableReporteAdmision"
          />
        </div>

        <div class="row row-table">
          <table-data
            title="Puntaje PDT"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$puntaje_psu"
            :limit="20"
            metodo="datatable"
            name="simulaciones"
            tableTemplate="TableReporteAdmision"
          />
        </div>

        <div class="row row-table">
          <table-data
            title="Becas del estado"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$postulacion.carrera.nombre"
            match="Beca Juan Gómez Millás"
            :limit="20"
            metodo="datatable_bybeca"
            name="simulaciones"
            tableTemplate="TableReporteAdmisionCBBecas"
          />
        </div>

        <div class="row row-table">
          <table-data
            title="Carreras con gratuidad"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$postulacion.carrera.nombre"
            match="si"
            :limit="20"
            metodo="datatable_bygratuidad"
            name="simulaciones"
            tableTemplate="TableReporteAdmisionCBYN"
          />
        </div>

        <div class="row row-table">
          <table-data
            title="Ruts con más recurrencia en simulaciones"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$numero_documento"
            :limit="20"
            metodo="datatable"
            name="simulaciones"
            tableTemplate="TableReporteAdmision"
          />
        </div>
        <div class="row row-table">
          <table-data
            title="Ruts con más recurrencia en simulaciones de puntaje"
            v-bind:source="mongo + '/' + db + '/'"
            group="$numero_documento"
            :limit="20"
            metodo="datatable_sinunwind"
            name="simulacion_puntaje"
            tableTemplate="TableReporteAdmision"
          />
        </div>
        <div class="row row-table">
          <table-data
            title="Ruts con más recurrencia en cotizaciones"
            v-bind:source="mongo + '/' + db + '/'"
            unwind="$postulacion"
            group="$rut"
            :limit="20"
            metodo="datatable"
            name="cotizaciones"
            tableTemplate="TableReporteAdmision"
          />
        </div>
        <div class="row row-table">
          <table-data
            title="Ruts con más recurrencia en contacto"
            v-bind:source="mongo + '/' + db + '/'"
            group="$rut"
            :limit="20"
            metodo="datatable_sinunwind"
            name="contacto"
            tableTemplate="TableReporteAdmision"
          />
        </div>
        <div class="row row-table">
          <table-data
            title="Ruts con más recurrencia en click to call"
            v-bind:source="mongo + '/' + db + '/'"
            group="$rut"
            :limit="20"
            metodo="datatable_sinunwind"
            name="clicktocall"
            tableTemplate="TableReporteAdmision"
          />
        </div>
      </div>
    </section>
  </main>
</template>
<script>
  import { computed } from 'vue'
  import { useStore } from 'vuex'
  import TableData from '@/components/TableData.vue'

  export default {
    name: 'Conversiones',
    components: {
      'table-data': TableData,
    },
    setup() {
      const store = useStore()
      const mongo = computed(() => store.getters.getMongoURL)
      const db = computed(() => store.getters.getDBDashboard)
      //const analytics = computed(() => store.getters.getAnalyticsURL)
      return { mongo, db }
    },
  }
</script>
